<template>
  <!-- 登录页面-平台 -->
  <div id="login">
    <!-- App端 -->
    <header v-if="isApp" class="app">
      <div class="app-header-container" id="appHeader">
        <div class="header-info">
          <div class="plat-info">
            <div class="left">
              <img
                :src="platform.logo.img_url"
                @click="$utils.goIndexPage"
                class="logo"
              />
              <span>{{ platform.platform.name }}</span>
            </div>
          </div>
          <div class="welcome-text-info">
            <div class="welcome" v-if="loginInfo.disseminate_type == 'text'">
              {{ loginInfo.text }}
            </div>
          </div>
        </div>
        <el-carousel
          indicator-position="outside"
          class="carousel-box"
          height="100%"
          :interval="3000"
        >
          <el-carousel-item v-for="(item, index) in loginImgArr" :key="index">
            <img
              :src="item.attachment_path"
              style="height: 100%; width: 100%; object-fit: cover"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </header>
    <!-- PC端 -->
    <div class="header" v-else>
      <div class="content">
        <div class="plat-info">
          <img
            :src="platform.logo.img_url"
            @click="clickLogo"
            class="logo"
          />
          {{ platform.platform.name }}
        </div>
        <div class="regesit-enter" @click="gotoLinks(loginInfo.link)">
          <div class="info" v-if="loginInfo.disseminate_type == 'text'">
            {{ loginInfo.text }}
          </div>
          <div class="left" v-if="loginInfo.disseminate_type == 'img'">
            <img :src="loginInfo.img.attachment_path" class="welcome-img" />
          </div>
        </div>
      </div>
    </div>

    <!-- APP端页面 取消了微信登录 和 忘记密码 -->
    <div v-if="isApp" class="app-body">
      <div class="content">
        <div class="app-login-type">
          <div
            :class="['app-type', active == 1 ? 'curr' : '']"
            @click="active = 1"
          >
            验证码登录
          </div>
          <div
            :class="['app-type', active == 2 ? 'curr' : '']"
            @click="active = 2"
          >
            密码登录
          </div>
        </div>
        <!-- 输入验证码 -->
        <div class="inputs app-el-form" v-if="active == 1">
          <el-form>
            <el-form-item>
              <el-input
                placeholder="请输入手机号"
                v-model.number="phone"
                class="input-with-select"
              >
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="is_valid">
              <huakuaiApp
                ref="huakuai"
                width="420px"
                :mobile="phone"
                @isOk="isOk"
              ></huakuaiApp>
            </el-form-item>
            <el-form-item>
              <div class="code-box">
                <el-input
                  style="margin-right: 20px"
                  v-model="mobile_verity_code"
                  placeholder="请输入验证码"
                ></el-input>
                <div class="code-msg-btn">
                  <el-button
                    @click="getCodes"
                    :disabled="disable"
                    style="width: 100%;padding: 12px 13px !important;"
                    >{{ showWorld }}</el-button
                  >
                </div>
              </div>
            </el-form-item>
            <div class="btns">
              <el-button
                @click="codeLogin"
                style="
                  width: 100%;
                  font-size: 1.4em;
                  line-height: 1.5;
                  border-radius: 0.2em;
                "
                type="danger"
                >登录</el-button
              >
            </div>
          </el-form>
        </div>
        <!-- 密码登录 -->
        <div class="inputs set-box" v-if="active == 2">
          <el-form class="app-el-form" ref="pwdForm">
            <el-form-item prop="value1">
              <el-input v-model="account" placeholder="请输入用户名">
                <template slot="prepend">
                  <img
                    src="@/assets/img/login/userIcon.png"
                    style="width: 1.4rem; height: 1.4rem"
                  />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="value2">
              <el-input
                type="password"
                show-password
                v-model="password"
                placeholder="请输入密码"
              >
                <template slot="prepend">
                  <img
                    src="@/assets/img/login/passIcon.png"
                    style="width: 1.4rem; height: 1.4rem"
                  />
                </template>
              </el-input>
            </el-form-item>
            <div>
              <el-button
                style="
                  width: 100%;
                  font-size: 1.4rem;
                  line-height: 1.5;
                  border-radius: 0.2rem;
                "
                type="danger"
                @click="pwdLogin"
                >登录</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <!-- PC端 -->
    <div class="body" id="loginBg" v-else>
      <el-carousel
        indicator-position="outside"
        class="carousel-box"
        height="100%"
        :interval="3000"
      >
        <el-carousel-item v-for="(item, index) in loginImgArr" :key="index">
          <img
            :src="item.attachment_path"
            style="height: 100%; width: 100%; object-fit: cover"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="content">
        <div class="loginBox">
          <!-- 左上角切换方式 -->
          <div v-if="loginType == 1">
            <img
              class="qrCode"
              src="@/assets/img/login/QrIcon.png"
              @click="qrcodelogin"
            />
            <img class="qrIcon" src="@/assets/img/login/QrCode.png" />
          </div>
          <div v-if="loginType == 2">
            <img
              class="qrCode"
              src="@/assets/img/login/phoneCode.png"
              @click="loginType = 1"
            />
            <img class="qrIcon" src="@/assets/img/login/phone.png" />
          </div>
          <!-- end of 左上角切换方式 -->
          <!-- 输入框登录 -->
          <div class="account" v-if="loginType == 1">
            <div class="title">
              <span
                :class="{ active: active == 1 }"
                @click="active = 1"
                style="width: 140px"
                >验证码登录</span
              >
              <span
                :class="{ active: active == 2 }"
                @click="active = 2"
                style="width: 112px"
                >密码登录</span
              >
            </div>
            <div class="inputs" v-if="active == 1">
              <div class="box box2">
                <el-input
                  placeholder="请输入用户手机号"
                  v-model="phone"
                  :maxlength="13"
                  class="input-with-select"
                  @input="onInput"
                  ref="inputRef"
                >
                  <template slot="prepend">+86</template>
                </el-input>
              </div>
              <huakuai :mobile="phone" @isOk="isOk" ref="huakuai" />
              <div class="box box2">
                <el-input
                  style="margin-right: 20px;font-size:12px"
                  v-model="mobile_verity_code"
                  placeholder="请输入验证码"
                ></el-input>
                <el-button
                  @click="getCodes"
                  :disabled="disable"
                  style="width: 160px"
                  >{{ showWorld }}</el-button
                >
              </div>
              <div class="btn login-btn" @click="codeLogin">登录</div>
            </div>
            <div class="inputs" v-if="active == 2">
              <div class="box">
                <img src="@/assets/img/login/userIcon.png" />
                <input
                  @input="onAccount"
                  ref="accountRef"
                  :maxlength="13"
                  placeholder="用户名"
                  v-model.trim="account"
                />
              </div>
              <div class="box">
                <img src="@/assets/img/login/passIcon.png" />
                <input
                  placeholder="请输入密码"
                  type="password"
                  v-model="password"
                />
              </div>
              <div class="btn login-btn" @click="pwdLogin">登录</div>
              <div class="password">
                <div class="remember" @click="remember = !remember">
                  <checkBox :checked="remember"></checkBox>
                  <span>记住密码</span>
                </div>
                <div class="forget" @click="$router.push('/resgister/forget')">
                  忘记密码？
                </div>
              </div>
            </div>
          </div>
          <!-- end of 输入框登录 -->
          <!-- 扫码登录 -->
          <div class="account" v-if="loginType == 2 && !is_bind_phone">
            <div class="title">
              <span class="QrCode">扫码登录</span>
            </div>
            <div class="inputs" style="overflow:hidden">
              <div class="Qr">
                <img :src="qrcode.img" />
              </div>
              <div class="scan">
                <img src="@/assets/img/login/scan.png" />
                <div class="text">
                  <p>打开<span>APP</span></p>
                  <p>扫一扫登录</p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of 扫码登录 -->
          <!-- 绑定手机号 -->
          <div class="account" v-if="loginType == 3 && is_bind_phone">
            <div class="title">
              <span style="width: 140px" class="active">绑定手机号</span>
            </div>
            <div class="inputs">
              <div class="box box2">
                <el-input
                  placeholder="请输入内容"
                  v-model.number="bind_mobile"
                  class="input-with-select"
                >
                  <template slot="prepend">+86</template>
                </el-input>
              </div>
              <huakuai :mobile="bind_mobile" @isOk="isOk" ref="huakuai" />
              <div class="box box2">
                <el-input
                  style="margin-right: 20px"
                  v-model="mobile_verity_code"
                  placeholder="请输入验证码"
                ></el-input>
                <el-button
                  @click="getVerifyCode"
                  :disabled="disable"
                  style="width: 160px"
                  >{{ showWorld }}</el-button
                >
              </div>
              <div class="btn" @click="bindMobileBtn">绑定手机号</div>
            </div>
          </div>
          <div class="bottom">
            <div class="other">
              <template v-if="is_social_login">
                <span>其他登录方式</span>
                <label></label>
                <ul>
                  <li>
                    <img src="@/assets/img/login/wx.png" @click="wxLogin" />
                  </li>
                </ul>
              </template>
            </div>
            <div class="resgister" @click="$router.push('/resgister')">
              <span>立即注册</span>
              <img src="@/assets/img/login/resgister.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers :navBar="navBar" v-if="!isApp" />
  </div>
</template>

<script>
import footers from "@/components/footers.vue";
import huakuai from "./huakuai.vue";
import huakuaiApp from "./huakuaiApp.vue";
import checkBox from "../components/checkBox.vue";
import { isMobile } from "@/utils/wxConfig.js";
export default {
  components: {
    checkBox,
    huakuai,
    huakuaiApp,
    footers,
  },
  data() {
    return {
      active: 2,
      loginType: 1,
      is_bind_phone: false,
      options: [
        {
          value: "选项1",
          label: "+86",
        },
      ],
      loginInfo: {},
      platform: {
        logo: {},
        platform: {},
      },
      navBar: [],
      value: "",
      phone: "",
      is_verify: !1,
      mobile_verity_code: "",
      account: "",
      password: "",
      remember: false,
      mobile_err: "",
      bind_mobile: "",
      AccessToken: "",
      key: "",
      select: "1",
      disable: true,
      showWorld: "点击获取验证码",
      loginImgArr: [],
      err: {
        phone: "",
        mobile_verity_code: "",
        account: "",
        password: "",
      },
      saveData: {},
      code_valid: false, //验证码是否滑动
      n: 10,
      timers: null,
      login_url: "",
      is_social_login: false,
      isApp: false,
      curInputObj: {
        value: "",
      },
      accountObj: {
        value: "",
      },
      routerUrl: "",
      qrcode: {
        img: "",
        id: "",
      },
      scan_timers: null,
    };
  },
  watch: {
    "$route.query": {
      deep: true,
      handler() {
        this.wxAuth();
      },
    },
    active(value) {
      if (value == 2) {
        if (localStorage.user) {
          let user = JSON.parse(localStorage.user);
          this.account = user.u;
          this.password = user.p;
          this.remember = !0;
        }
      }
    },
    loginType(value) {
      if (value == 2) {
        this.is_bind_phone = !1;
        let url = encodeURIComponent(
          "https://" + document.domain + "/#/login?auth=wx&state=auth"
        );
      }
    },
  },
  created() {
    this.wxAuth();
    this.getLoginPage();
    this.getThirdLogin();
    this.isApp = isMobile();
    console.log(this.$route);

    if (this.$route.query.url) {
      let urls = this.$route.query.url;
      let routerUrl = urls
      if (urls.indexOf("query")>-1) {
        routerUrl = urls
          .slice(0, urls.indexOf("query"))
          .replace("*jinhao", "#");
        let query = urls.slice(urls.indexOf("{"));
        if (query) {
          query = JSON.parse(query);
          for (let keys in query) {
            routerUrl += keys + "=" + query[keys] + "&";
         }
        }
        this.routerUrl = routerUrl.slice(0, -1);
      }else{
        this.routerUrl = routerUrl
      }
       
    }
    console.log(this.routerUrl);
  },
  mounted() {
    if (this.isApp) {
      // 1rem  相当于750尺寸设计图的 20px;
      (function() {
        var html = document.querySelector("html");
        html.style.fontSize = (window.innerWidth / 750) * 20 + "px";
        window.onresize = function() {
          html.style.fontSize = (window.innerWidth / 750) * 20 + "px";
        };
      })();
      var fun = function(doc, win) {
        var docEl = doc.documentElement,
          resizeEvt =
            "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function() {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            //这里是假设在750px宽度设计稿的情况下，1rem = 100px；
            //可以根据实际需要修改
            docEl.style.fontSize = 20 * (clientWidth / 750) + "px";
          };
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
      };
      fun(document, window);
    }
    this.value = this.options[0].label;
    let script = document.createElement("script");
    script.src =
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    let login = document.getElementById("login");
    this.getBgImg();
    login.appendChild(script);
    let query = this.$route.query;
    if (query.login && query.login == 2) {
      this.loginType = 1;
      this.active = 2;
    }
  },
  beforeDestroy() {
    clearTimeout(this.scan_timers);
  },
  methods: {
    clickLogo(){
      if(this.$store.state.finger) {
        return
      }
      this.$utils.goIndexPage();
    },
    gotoLinks(val) {
      if(this.$store.state.finger) {
        return
      }
      window.open(val);
    },
    getCodeLogin(client_id) {
      this.scan_timers = setTimeout(() => {
        this.$axios
          .getStatic("/api/files/code/state", { client_id })
          .then((res) => {
            console.log(res);
            if (res.data === "uploading") {
              this.getCodeLogin(client_id);
            } else {
              if (process.env.NODE_ENV === "production") {
                sessionStorage.setItem("AccessToken", res.data);
              } else {
                localStorage.setItem("AccessToken", res.data);
              }
              if (this.routerUrl) {
                location.href = location.origin + this.routerUrl;
              } else {
                this.$utils.webUrl(this.login_url);
              }
            }
          });
      }, 3000);
    },
    qrcodelogin() {
      this.qrcode = {
        img: "",
        id: "",
      };
      let id = Math.random()
        .toString(36)
        .slice(2);
      this.$axios
        .postStatic("/api/files/code/gen", {
          content:
            location.origin + "/h5/#/scan?scan_mode=login_scan&scan_id=" + id,
          img_size: "200",
          client_id: id,
        })
        .then((res) => {
          this.loginType = 2;
          this.qrcode = {
            img: res.data,
            id,
          };
          this.getCodeLogin(id);
        });
    },
    onAccount(val) {
      // accountRef
      val = this.account.replace(/\D/g, "").substring(0, 11);
      if (!Number(val)) {
        return;
      }
      const nowIndex = this.getCursortPositionaccount(this.$refs.accountRef);
      console.log(nowIndex, this.account, val);
      if (val.length > 3 && val.length < 8) {
        this.account = `${val.substr(0, 3)} ${val.substr(3)}`;
      } else if (val.length >= 8) {
        this.account = `${val.substr(0, 3)} ${val.substr(3, 4)} ${val.substr(
          7
        )}`;
      } else {
        this.account = val;
      }
      console.log(nowIndex);
      this.setCurIndexaccount(
        nowIndex,
        this.account.length - this.accountObj.value.length
      );
    },
    getCursortPositionaccount(element) {
      let CaretPos = 0;
      CaretPos = element.selectionStart;
      return CaretPos;
    },

    setCurIndexaccount(nowIndex, value) {
      const len = value;
      this.accountObj.value = this.account;
      setTimeout(() => {
        let pos = nowIndex;
        // 新增操作
        if (len > 0) {
          if (nowIndex === 4 || nowIndex === 9) {
            pos += 1;
          }
        } else if (len < 0) {
          // 删除操作
          if (nowIndex === 4 || nowIndex === 9) {
            pos -= 1;
          }
        }
        this.$refs.accountRef.selectionStart = pos;
        this.$refs.accountRef.selectionEnd = pos;
      }, 0);
    },

    onInput(val) {
      val = this.phone.replace(/\D/g, "").substring(0, 11);
      const nowIndex = this.getCursortPosition(this.$refs.inputRef);
      console.log(nowIndex, this.phone, val);
      if (val.length > 3 && val.length < 8) {
        this.phone = `${val.substr(0, 3)} ${val.substr(3)}`;
      } else if (val.length >= 8) {
        this.phone = `${val.substr(0, 3)} ${val.substr(3, 4)} ${val.substr(7)}`;
      } else {
        this.phone = val;
      }
      console.log(nowIndex);
      this.setCurIndex(
        nowIndex,
        this.phone.length - this.curInputObj.value.length
      );
    },

    getCursortPosition(element) {
      let CaretPos = 0;
      CaretPos = element.$refs.input.selectionStart;
      return CaretPos;
    },

    setCurIndex(nowIndex, value) {
      const len = value;
      console.log(len);
      this.curInputObj.value = this.phone;
      setTimeout(() => {
        let pos = nowIndex;
        // 新增操作
        if (len > 0) {
          if (nowIndex === 4 || nowIndex === 9) {
            pos += 1;
          }
        } else if (len < 0) {
          // 删除操作
          if (nowIndex === 4 || nowIndex === 9) {
            pos -= 1;
          }
        }
        this.$refs.inputRef.$refs.input.selectionStart = pos;
        this.$refs.inputRef.$refs.input.selectionEnd = pos;
      }, 0);
    },
    gotoZuce() {
      //租户入驻
      this.$router.push({
        path: "/register",
      });
    },
    gotoOperation() {
      //运营方入驻
      this.$router.push({
        path: "/register/platform",
      });
    },
    getThirdLogin() {
      this.$axios.get("/setting/public/social_login").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          if (data && data.length > 0) {
            for (let i = 0, len = data.length; i < len; i++) {
              let item = data[i];
              if (item.key == "wechat") {
                if (item.value && item.value.is_on) {
                  this.is_social_login = true;
                  break;
                }
              }
            }
          }
        }
      });
    },
    getLoginPage() {
      //获取登录落地页
      this.$axios.get("/setting/public/login_after_page").then((res) => {
        if (res.code == 200) {
          let link = res.data?.url;
          this.login_url = link || "/mySelf/index";
        }
      });
    },
    getBgImg() {
      // this.$axios.get
      this.$axios
        .get("/setting/public/reglogin")
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            let loginSet = {},
              loginImgArr = [];
            data.map((item) => {
              //背景图片
              if (item.key == "login_reg_pic_coll") {
                let dataArr = item.value.items;
                for (let i = 0, len = dataArr.length; i < len; i++) {
                  if (dataArr[i].status == 1) {
                    loginSet = dataArr[i];
                    loginImgArr.push(dataArr[i]);
                  }
                }
              }
              //底部导航
              if (item.key == "login_reg_bottom_nav") {
                if (
                  item.value &&
                  item.value.items &&
                  item.value.items instanceof Array
                ) {
                  this.navBar = item.value.items.filter(
                    (its) => its.status == 1
                  );
                }
              }
              //头部欢迎语
              if (item.key == "login_reg_base_setting") {
                this.loginInfo = item.value;
              }
            });
            if (loginImgArr.length > 0) {
              this.loginImgArr = loginImgArr;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          err.msg && this.$message.error(err.msg || "系统错误,联系管理员");
        });
      //获取平台名称
      this.$axios.get("/setting/platform/name_logo").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          if (data.value) {
            this.platform = data.value;
            let platInfo = JSON.parse(JSON.stringify(data.value.platform));
            platInfo.logo_url = data.value.logo.img_url;
            platInfo.time = +new Date();
            platInfo.favicon = data.value.favicon;
            localStorage.platInfo = JSON.stringify(platInfo);
          }
        }
      });
    },
    wxLogin() {
      this.$router.push({
        path: "/loginWx",
      });
    },
    wxOkBtn(val) {
      //获取手机验证码
      this.saveData = val;
    },
    bindMobileBtn() {
      //绑定手机号
      if (!this.bind_mobile) {
        return this.$message.error("请输入手机号");
      } else if (!this.mobile_verity_code) {
        return this.$message.error("请输入验证码");
      } else {
        let data = {
          token: this.AccessToken,
          key: this.key,
          mobile: Number(this.bind_mobile),
          mobile_verity_code: this.mobile_verity_code,
        };
        this.$axios
          .postJSON("/base/user/weixin/login/bind", data)
          .then((res) => {
            if (res.code == 200) {
              let data = res.data;
              if (process.env.NODE_ENV === "production") {
                sessionStorage.setItem("AccessToken", data.AccessToken);
              } else {
                localStorage.setItem("AccessToken", data.AccessToken);
              }
              this.$message.success("绑定成功");
              this.$router.push({
                path: "/mySelf/index",
              });
            }
          })
          .catch((err) => {
            if (err.code == 20005) {
              this.active = 1;
              this.loginType = 1;
            }
          });
      }
    },
    wxAuth() {
      let query = this.$route.query;
      if (query.code && query.auth == "wx") {
        this.$axios
          .postJSON("/base/user/weixin/login", { code: query.code })
          .then((res) => {
            if (res.code == 200) {
              let data = res.data;
              if (data.next == "true") {
                this.loginType = 3;
                this.is_bind_phone = !0;
                this.$message.error("请先绑定手机号");
                this.AccessToken = data.token;
                this.key = data.key;
              } else {
                if (res.data.AccessToken) {
                  if (process.env.NODE_ENV === "production") {
                    sessionStorage.setItem("AccessToken", res.data.AccessToken);
                  } else {
                    localStorage.setItem("AccessToken", res.data.AccessToken);
                  }
                  this.$router.push({
                    path: "/mySelf/index",
                  });
                }
              }
            }
          })
          .catch((err) => {
            this.is_bind_phone = !0;
          });
      }
    },
    getVerifyCode() {
      let val = this.saveData;
      if (!this.bind_mobile) {
        return this.$message.error("请输入手机号码");
      } else if (!this.$utils.regPhone(this.bind_mobile)) {
        return this.$message.error("请输入正确的手机号码");
      } else if (this.$utils.isEmpty(val)) {
        return this.$message.error("请输入右移滑块验证");
      }
      let parma = {
        mobile: Number(this.bind_mobile),
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      this.$axios
        .postJSON("/base/user/weixin/login/bind/send_code", parma)
        .then(
          (res) => {
            if (res.code == 200) {
              this.$message.success("获取成功");
              const that = this;
              this.timers = setInterval(() => {
                that.n--;
                if (that.n < 1) {
                  clearInterval(that.timers);
                  that.$refs.huakuai.resets();
                  that.showWorld = "点击获取验证码";
                  that.n = 60;
                } else {
                  that.showWorld = that.n + "秒后再次获取";
                  that.disable = true;
                }
              }, 1000);
            }
          },
          (err) => {
            this.$refs.huakuai.resets();
          }
        );
    },
    mobileErr(data) {
      this.$message.error(data.msg);
    },
    verifyCode(data) {
      this.mobile_verity_code = data;
    },
    isOk(val) {
      if (val.isOk) {
        this.disable = false;
        this.code_valid = true;
        this.saveData = val;
      }
    },
    getCodes() {
      if (!this.code_valid) {
        this.$refs.huakuai.resets();
        return this.$message.error("请向右滑动滑块验证");
      }
      let val = this.saveData;
      console.log(this.phone);
      let parma = {
        mobile: Number(
          this.phone
            .toString()
            .replace(/\D/g, "")
            .substring(0, 11)
        ),
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      this.$axios.postJSON("/base/user/send/mobilecode", parma).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.showWorld = "点击获取验证码";
                that.n = 60;
                that.code_valid = false;
                that.disable = false;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.disable = true;
              }
            }, 1000);
          }
        },
        (err) => {
          this.$refs.huakuai.resets();
        }
      );
    },
    pwdLogin() {
      //密码登录
      let account = this.account.replace(/\D/g, "").substring(0, 11),
        password = this.password;
      if (!account) {
        this.$message.error("请输入账号");
      } else if (!password) {
        this.$message.error("请输入密码");
      } else {
        let data = {
          u: account,
          p: password,
        };
        this.$axios.postJSON("/base/user/login", data).then((res) => {
          if (res.code == 200) {
            if (process.env.NODE_ENV === "production") {
              sessionStorage.setItem("AccessToken", res.data.AccessToken);
            } else {
              localStorage.setItem("AccessToken", res.data.AccessToken);
            }
            if (this.remember) {
              localStorage.user = JSON.stringify({ u: account, p: password });
            } else {
              localStorage.user = "";
            }
            if (this.routerUrl) {
              location.href = location.origin + this.routerUrl;
            } else {
              this.$utils.webUrl(this.login_url);
            }
          }
        });
      }
    },
    codeLogin() {
      let mobile = this.phone,
        mobile_verity_code = this.mobile_verity_code;
      if (!mobile) {
        this.$message.error("请输入手机号");
      } else if (!mobile_verity_code) {
        this.$message.error("请输入验证码");
      } else {
        let data = {
          mobile: Number(
            this.phone
              .toString()
              .replace(/\D/g, "")
              .substring(0, 11)
          ),
          mobile_verity_code: this.mobile_verity_code,
        };
        this.$axios.postJSON("/base/user/code/login", data).then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (process.env.NODE_ENV === "production") {
              sessionStorage.setItem("AccessToken", res.data.AccessToken);
            } else {
              localStorage.setItem("AccessToken", res.data.AccessToken);
            }
            if (this.routerUrl) {
              location.href = location.origin + this.routerUrl;
            } else {
              this.$utils.webUrl(this.login_url);
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.app-header-container {
  font-size: calc(100vw / 750 * 20);

  box-sizing: border-box;
  height: 20.5rem;
  .header-info {
    position: relative;
    padding: 1.5em 1em;
    z-index: 5;
  }
  .carousel-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 20.5rem;
    width: 100vw;
    z-index: 3;
  }
  .plat-info {
    display: flex;
    font-size: 1.6em;
    color: #ffffff;
    height: 3.2em;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 3em;
        margin-right: 1em;
        height: auto;
      }
    }
    .icon-user {
      width: 1em;
      height: 1em;
    }
  }
  .welcome-text-info {
    margin-top: 1em;
    font-size: 1.8em;
    color: #ffffff;
    text-align: center;

    .left {
      img {
        height: 2em;
        width: auto;
      }
    }
  }
  .login-app {
    text-align: right;
    font-size: 1.3em;
    color: #666;
    .point {
      color: #f72626;
    }
  }
}
.app-body {
  margin: 0 1.5rem;
  background-color: #ffffff;
  padding: 2rem 1.5rem 2rem;
  border-radius: 1.5rem 1.5rem 0 0;
  position: relative;
  top: -2rem;
  z-index: 12;
  .app-login-type {
    display: flex;
    margin-bottom: 3rem;
    .app-type {
      flex: 1;
      text-align: center;
      font-size: 1.6rem;
      &.curr {
        color: #f72626;
        position: relative;
        &::after {
          content: "";
          display: block;
          flex: 0;
          position: absolute;
          top: 110%;
          left: 50%;
          transform: translateX(-50%);
          width: 3.5rem;
          height: 0.2rem;
          background-color: #f72626;
        }
      }
    }
  }

  .code-box {
    display: flex;
    .code-msg-btn {
      width: 8em;
      margin-left: 0.5em;
    }
  }
  .password-info {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    color: #666666;
  }
  .tiaoguo {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
}
.agreement-box {
  display: flex;
  margin-bottom: 1.5rem;
  .agree-box {
    font-size: 1.4rem;
    color: #666666;
  }
}
.app-password {
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  .forget {
    color: #999999;
  }
}

.addnewd {
  width: 68px !important;
  height: 48px !important;
  line-height: 48px !important;
  background-color: #fff !important;
  text-align: center !important;
  font-size: 18px !important;
  color: #ccc;
}
.carousel-box {
  height: 100%;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
}

#login {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  > .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    > .content {
      position: relative;
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .plat-info {
        display: flex;
        align-items: center;
        font-size: 22px;
        .logo {
          height: 30px;
          width: auto;
          margin-right: 24px;
          cursor: pointer;
        }
      }
      .info {
        font-size: 16px;
        white-space: pre;
      }
      .regesit-enter {
        font-size: 15px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;
        cursor: pointer;
        .platform {
          margin-right: 12px;
        }
        .welcome-img {
          width: auto;
          height: 40px;
        }
      }
    }
  }
  > .body {
    width: 100%;
    flex: 1;
    position: relative;
    > .content {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      flex: 0;
      > .loginBox {
        width: 500px;
        height: 500px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
        .qrCode {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          cursor: pointer;
        }
        .qrIcon {
          position: absolute;
          width: 79px;
          height: 31px;
          top: 20px;
          right: 80px;
        }
        > .account {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          > .title {
            display: flex;
            align-items: center;
            padding-left: 27px;
            margin-top: 50px;
            margin-bottom: 29px;
            > span {
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              cursor: pointer;
            }
            > .active {
              color: #f74848;
              position: relative;
              &::after {
                content: "";
                display: block;
                width: 80px;
                height: 2px;
                background-color: #f74848;
                position: absolute;
                bottom: -4px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            .QrCode {
              line-height: 40px;
              font-size: 28px;
              color: #f74848;
            }
          }
          > .inputs {
            flex: 1;
            overflow: auto;
            > .box {
              display: flex;
              align-items: center;
              border: 1px solid #dce0e7;
              border-radius: 3px;
              width: 440px;
              margin: 0 auto;
              margin-bottom: 20px;
              > .el-select {
                width: 80px;
                height: 53px;
                > .el-input {
                  height: 100%;
                  > .el-input__inner {
                    border: none;
                    height: 53px;
                    font-size: 16px;
                    line-height: 53px !important;
                  }
                }
              }
              > span {
                width: 1px;
                height: 25px;
                background-color: #dce0e7;
              }
              > img {
                width: 25px;
                height: 25px;
                margin-left: 15px;
              }
              > input {
                height: 48px;
                border: none;
                padding-left: 10px;
                width: 300px;
                border-radius: 3px;
                color: #333;
                font-size: 16px;
              }
              > input::-webkit-input-placeholder {
                font-size: 16px;
                color: #ccc;
              }
            }
            > .box2 {
              border: none;
              margin-top: 18px;
            }
            > .btn {
              width: 440px;
              margin: 0 auto;
              border-radius: 3px;
              line-height: 48px;
              text-align: center;
              background-color: #f74848;
              color: #fff;
              cursor: pointer;
              letter-spacing: 10px;
              margin-top: 40px;
              &.login-btn {
                font-size: 18px;
              }
            }
            > .password {
              width: 440px;
              margin: 0 auto;
              margin-top: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              > .remember {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 14px;
              }
              > .forget {
                cursor: pointer;
                color: #999;
                font-size: 14px;
              }
            }
            > .Qr {
              display: flex;
              justify-content: center;
              align-items: center;
              .qr-img {
                width: 200px;
                height: 200px;
              }
              > img {
                width: 200px;
                height: 200px;
              }
            }
            > .scan {
              display: flex;
              align-items: center;
              width: 200px;
              margin: 0 auto;
              margin-bottom: 60px;
              > img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                margin-left: 5px;
              }
              > .text {
                > p {
                  line-height: 18px;
                  > span {
                    color: #999;
                    margin-left: 5px;
                  }
                }
              }
            }
            > .down {
              color: #999;
              width: 440px;
              text-align: right;
              margin: 0 auto;
              > span {
                cursor: pointer;
              }
            }
            .input-error-box {
              height: 2px;
              position: relative;
              .input-error {
                position: absolute;
                top: 12px;
                left: 60px;
                color: #f74848;
              }
            }
          }
        }
        > .bottom {
          height: 80px;
          width: 100%;
          border-top: 1px solid #dce0e7;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 60px;
          box-sizing: border-box;
          > .other {
            font-size: 16px;
            display: flex;
            align-items: center;
            > label {
              width: 1px;
              height: 25px;
              background-color: #dce0e7;
              margin-left: 20px;
            }
            > ul {
              display: flex;
              align-items: center;
              > li {
                margin-left: 15px;
                cursor: pointer;
                > img {
                  width: 22px;
                  height: 22px;
                }
              }
            }
          }
          > .resgister {
            font-size: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            > img {
              width: 16px;
              height: 16px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
<style lang="scss">
#loginBg {
  .el-carousel__indicators {
    display: none;
  }
}
.app-el-form {
  font-size: calc(100vw / 750 * 20);
  // el-form组件代码
  .el-form-item {
    margin-bottom: 3rem;
  }
  .el-form-item__content {
    line-height: 1em;
    font-size: 1.4rem;
  }
  .el-input {
    font-size: 1.4rem;
  }
  .el-input__suffix {
    right: 1rem;
  }
  .el-input .el-input__clear {
    font-size: 1.4rem;
    line-height: 3rem;
  }
  .el-input__inner {
    line-height: 1;
    font-size: 1.4rem;
    height: 2.5em;
    padding-left: 1rem;
  }
  .el-form-item--small .el-form-item__error {
    padding-top: 0.5rem;
  }
  .el-form-item__error {
    font-size: 1.3rem;
  }
  .el-button {
    border-radius: 0.2em;
  }
  .el-button--danger {
    line-height: 1.75;
    font-size: 1.4rem;
  }
  .el-checkbox {
    font-size: 1.2rem;
    margin-right: 0.4em;
  }
  .el-checkbox__inner {
    width: 1.4rem;
    height: 1.4rem;
    &::after {
      width: 0.5rem;
      height: 0.7rem;
      border: 0.2rem solid #ffffff;
      border-left: 0;
      border-top: 0;
      left: 0.3rem;
    }
  }
}
</style>
