<template>
  <!-- 多选框组件 -->
  <div id="checkBox" @click="$emit('change')">
    <div
      class="icon"
      :style="{ width: width, height: height }"
      :class="{ active: checked }"
    >
      <img src="@/assets/img/invoice/checked.png" v-if="checked" />
    </div>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "20px",
    },
    height: {
      type: String,
      default: "20px",
    },
    label: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#checkBox {
  display: flex;
  align-items: center;
  color: #999;
  cursor: pointer;
  > .icon {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 16px;
      height: 16px;
    }
  }
  > .active {
    background-color: #f74848;
    border-color: #f74848;
  }
  > label {
    cursor: pointer;
  }
}
</style>
